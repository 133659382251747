var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.getElementStyle)},[_c('el-row',{attrs:{"align":"middle"}},[_c('el-col',{style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[_c('label',{attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label))]),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(!_vm.isDataTableField)?_c('span',[(!_vm.isDataTableField)?_c('i',{staticClass:"el-icon-copy-document",on:{"click":_vm.copyDocument}}):_vm._e()]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]),(_vm.isActive || _vm.isDataTableField)?_c('el-row',{staticClass:"setting-icon"},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e(),_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 12 : 24}},[[_c('currency-input',{style:(_vm.getStyle),attrs:{"value":_vm.value,"options":{
              locale: _vm.data.validations.locale,
              currency: _vm.data.validations.currency,
              hideCurrencySymbolOnFocus: false,
              hideGroupingSeparatorOnFocus: true,
              hideNegligibleDecimalDigitsOnFocus: true,
              autoDecimalDigits: true,
              autoSign: true,
              useGrouping: true,
              accountingSign: false,
            },"inputTextAlignments":_vm.data && _vm.data.styles && _vm.data.styles.inputTextAlignments
                ? _vm.data.styles.inputTextAlignments
                : 'left',"placeholder":_vm.data.placeholder}})]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }